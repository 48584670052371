.pingPong{
  --player-x: 0px;
  --player-y: 0px;
  --ball-x: 50%;
  --ball-y: 50%;
}

.player{
  width: 10px;
  height: 100px;
  left: var(--player-x);
  top: var(--player-y);
  background: white;
  position: fixed;
  z-index: 3;
  pointer-events: none;
}

.ball{
  width: 15px;
  height: 15px;
  left: var(--ball-x);
  top: var(--ball-y);
  background: white;
  position: fixed;
  z-index: 3;
  border-radius: 100%;
}