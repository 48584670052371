.App {
    --width-padding: 5%;
}

.App .header{
    position: relative;
}

.App .header .img{
    position: absolute;
    opacity: 0;
    transition: opacity 1s;
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}

.App .header h1{
    position: absolute;
    left: var(--width-padding);
    bottom: 0;
    background-color: var(--color-light);
    margin: 0;
    padding: 5px 10px;
    z-index: 2;
    transition: background-color 1s;
}

.App .header img{
    display: none;
}

.App .header .me{
    position: relative;
    z-index: 2;
    height: 70%;
    border-radius: 100%;
    margin: 0 auto;
    top: 15%;
    width: fit-content;
    overflow: hidden;
    border: 5px solid var(--color-light);
    box-sizing: border-box;
    transition: border 1s;
}

.App .header .me img{
    display: block;
    height: 100%;
}

.App .header .me:before{
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    background: var(--color-dark);
    position: absolute;
    top: 0;
    left: 0;
    opacity: .5;
    filter: brightness(160%);
    transition: background-color 1s;
}

.App .content{
    padding: 10px var(--width-padding);
    max-width: 600px;
    margin: 0 auto;
}

.App button{
    cursor: pointer;
    background: var(--color-dark);
    color: var(--color-light);
    border: none;
    margin: 0 .3em;
    transition: background-color .3s, color .3s;
}

.App button:hover{
    background: var(--color-light);
    color: var(--color-dark);
    filter: brightness(80%);
}

.App ul {
    padding-left: var(--width-padding);
}

/*.App .skills{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.App .skills > li{
    flex-shrink: 0;
}*/

.App .collapsable-wrapper {
    margin: .3em;
}

.App .collapsable-wrapper .collapsable-content{
    padding: 1em;
    border-left: 2px solid var(--color-dark);
}

@media (prefers-color-scheme: dark) {
    .App .header h1{
        background: var(--color-dark);
        transition: none;
    }

    .App button{
        background: var(--color-light);
        color: var(--color-dark);
    }

    .App button:hover{
        background: var(--color-dark);
        color: var(--color-light);
        filter: brightness(160%);
    }

    .App .collapsable-wrapper .collapsable-content{
        border-left: 2px solid var(--color-light);
    }
}

@media (max-width: 750px) {
    .App .header .me{
        top: 10%;
        height: 60%;
    }
    .App .header{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .App .header h1{
        position: relative;
        left: unset;
        bottom: unset;
        width: fit-content;
        margin: 0 auto;
    }
}